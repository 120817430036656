import React, { useEffect } from "react";
import { RetailerView } from "components/ezpay/secure/common/retail-cash-flow/retailer-view";
import { googlemapsApiKey } from "config";
import GoogleMap from "google-map-react";
import "components/ezpay/common/google-maps/map.scss";
import { useSelector } from "react-redux";
import { useRetailer } from "hooks";
import {
  getNearbyRetailerLocations,
  getSelectedRetailerLocations,
} from "selectors";
import { useBiller } from "hooks";
import { MapPin } from "components/ezpay/common/google-maps";
import { useSearchParams } from "react-router-dom";
import queryParameter from "utilities/queryParameter";

export const RetailerLocation = (): JSX.Element => {
  const { loadRetailers, getRetailers } = useRetailer();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loadBiller, getBiller } = useBiller();

  const nearbyRetailerLocations = useSelector(getNearbyRetailerLocations);
  const selectedStoreLocations = useSelector(getSelectedRetailerLocations);
  const retailers = getRetailers();
  const biller = getBiller();
  const zipCode = searchParams.get(queryParameter.zipCode);
  const selectedStoreId = searchParams.get(queryParameter.storeId);
  const selectedRetailerId = searchParams.get(queryParameter.retailerId);
  const userLat = searchParams.get(queryParameter.lat);
  const userLng = searchParams.get(queryParameter.lng);

  const onZipCodeQueryParametersChange = (zipCode: string | null, retailerId: string | null, storeId: string | null) => {
    if (zipCode && retailerId && storeId) {
      setSearchParams({ zipCode, retailerId, storeId });
    } else if (zipCode && retailerId) {
      setSearchParams({ zipCode, retailerId });
    } else if (zipCode) {
      setSearchParams({ zipCode });
    }
  }

  const onCoordinatesQueryParametersChange = (lat: string | null, lng: string | null, retailerId: string | null, storeId: string | null) => {
    if (lat && lng && retailerId && storeId) {
      setSearchParams({ lat, lng, retailerId, storeId });
    } else if (lat && lng && retailerId) {
      setSearchParams({ lat, lng, retailerId });
    } else if (lat && lng) {
      setSearchParams({ lat, lng });
    }
  }

  useEffect(() => {
    if (!biller) {
      loadBiller();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biller]);

  useEffect(() => {
    if (!retailers && biller) {
      loadRetailers(biller.clientId, biller.realm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailers, biller]);

  return (
    <div>
      {nearbyRetailerLocations?.length > 0 &&
        <div
          className="google-map"
          style={{ height: "100%" }}
          data-testid="google-map"
        >
          {/* @ts-ignore */}
          <GoogleMap
            style={{ width: "100%", height: "720px!important" }}
            bootstrapURLKeys={{ key: googlemapsApiKey }}
            center={{
              lat: selectedStoreLocations.find(_ => _.storeId === selectedStoreId)?.latitude ?? selectedStoreLocations[0]?.latitude ?? nearbyRetailerLocations[0].latitude,
              lng: selectedStoreLocations.find(_ => _.storeId === selectedStoreId)?.longitude ?? selectedStoreLocations[0]?.longitude ?? nearbyRetailerLocations[0].longitude,
            }}
            defaultZoom={14}
          >
            {selectedStoreLocations.map((location) => (
              <MapPin
                lat={location.latitude}
                lng={location.longitude}
                onSelectedStoreChange={() => {

                  onZipCodeQueryParametersChange(zipCode, selectedRetailerId, location.storeId);
                  onCoordinatesQueryParametersChange(userLat, userLng, selectedRetailerId, location.storeId);
                }}
                showStoreDetails={location.storeId === selectedStoreId}
                key={location.storeId}
                location={location}
              />
            ))}
          </GoogleMap>
        </div>
      }
      {retailers?.length > 0 &&
        <div className="retailer-pop-over"  data-testid="retailer-location-content">
          <RetailerView
            onZipCodeQueryParametersChange={onZipCodeQueryParametersChange}
            onCoordinatesQueryParametersChange={onCoordinatesQueryParametersChange}
            defaultZipCode={zipCode}
            defaultRetailerId={selectedRetailerId}
            mapMode={true}
            retailers={retailers}
            isOpen={true}
          />
        </div>
      }
    </div>
  );
};
export default RetailerLocation