import React, { useEffect, useState } from "react";
import {
  useRetailer,
} from "hooks";
import { getBiller } from "selectors";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import {
  Banner,
} from "kubra-ux-forge";
import { useNavigate, useOutletContext } from "react-router";
import { ISession } from "types/session";
import { getI18n, useTranslation } from "react-i18next";
import {
  BALANCE_NAMESPACE,
} from "constants/i18n-namespaces";
import { ContactAndTocFooter } from "components/ezpay/common/contact-and-toc-footer";
import { PageLoader } from "components/ezpay/common/page-loader";
import _ from "lodash";
import routing from "routing";
import { isMobile } from "react-device-detect";
import { CashView } from "../../../common/retail-cash-flow/cash-view";
import { DashboardSkeleton } from "components/ezpay/common/dashboard-skeleton";

export const SmsBarcodeView = (props: { isMobile: any }) => {
  const { t } = useTranslation(BALANCE_NAMESPACE);
  const { account, logout, login } = useOutletContext<ISession>();
  const navigate = useNavigate();

  const finalBiller = useSelector(getBiller);

  const { loadBillerConfiguration, getBillerConfiguration } = useRetailer();  
  const i18n = getI18n();
  const [emailBannerState, setEmailBannerState] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [smsBannerState, setSmsBannerState] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [loadingStates, setLoadingStates] = useState({
    loadConfig: true    
  });

  // get required values from subresources
  const imageResources: any = finalBiller?.properties?.find((element: any) => {
    return element.name === "ImageResources";
  });

  const updateLoadingState = (key: string, value: boolean) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  
  useEffect(() => {
    // Load config 
    const loadConfig = async () => {
      if (finalBiller !== null && finalBiller !== undefined) {
        try {
          await Promise.all([
            loadBillerConfiguration(finalBiller.clientId, account.realm),
          ]);
        } catch (error) {
          console.log('Error loading config:', error);
        } finally {
          updateLoadingState("loadConfig", false);
        }
      } else {
        updateLoadingState("loadConfig", false);
      }
    };

    loadConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalBiller]);

  // Monitoring loadingStates and updating isDataLoading accordingly
  useEffect(() => {
    const allDone = Object.values(loadingStates).every((state) => state === false);
    console.log("All done result :", allDone);
    if (allDone) {
      setIsDataLoading(false);
    }
  }, [loadingStates]);
  
  
  const billerConfiguration = getBillerConfiguration();

  const setCashView = (method: string) => {
    if (method === "CashView") {
      setEmailBannerState(false);
      setSmsBannerState(false);      
    } else if (method === "EmailModalSuccessClose") {
      setEmailBannerState(true);
      setSmsBannerState(false);
    } else if (method === "SmsModalSuccessClose") {
      setEmailBannerState(false);
      setSmsBannerState(true);
    } else {
      setEmailBannerState(false);
      setSmsBannerState(false);
    }
  };

  const handleEmailInputChange = (value: string) => {
    setEmailAddress(value);
  }

  const handleSmsInputChange = (value: string) => {
    setPhoneNumber(value);
  }

  if (isDataLoading) {
    return (<DashboardSkeleton visible={true}></DashboardSkeleton>);
  }
  
  return (

    <div>
      {/* banner for generic info and error */}
      <div className="ez-pay-balance-banner">
        {emailBannerState && (
                <Banner
                  autoCloseTimer={8000}
                  messageLabel={`${t("Cash barcode sent to " + emailAddress)}`}
                  variant="success"
                  withIcon
                  withClose                  
                  />
              )}
        {smsBannerState && (
                <Banner
                  autoCloseTimer={8000}
                  messageLabel={`${t("Cash barcode sent to " + phoneNumber)}`}
                  variant="success"
                  withIcon
                  withClose                  
                  />
              )}
      </div>      
      <div className="ez-pay-balance-frame">
        <div
          className="common-header payment-receipt-frame-header"
          style={{ width: "100%" }}
        >
        <div className="back-to-payment-dashboard">
          <button
            className="ez-pay-button-link"
            onClick={() => navigate(routing.balance.index())}
            data-cy="back-to-payments"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <span> {t("back-to-payment-dashboard")}</span>
          </button>
        </div>
        </div>
        <PageLoader />
        <div className="ez-pay-balance-top">
          {(
            <div>
              <CashView
                biller={finalBiller}
                accountNumber={account.accountNumber}
                billerLogo={imageResources?.properties?.logoURL ?? ""}
                billerConfiguration={billerConfiguration}
                userAccount={account}
                flowCallback={setCashView}
                realm={account.realm}
                emailInputChange={handleEmailInputChange}
                smsInputChange={handleSmsInputChange}
                ></CashView>
            </div>
          )}
          <ContactAndTocFooter biller={finalBiller} isMobile={isMobile} />
        </div>
      </div>
    </div>
  );
};
export default SmsBarcodeView;