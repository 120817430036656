// https://kubra.jira.com/wiki/spaces/MIC/pages/476676243/Payment+Transaction+Types+and+Statues

import { mappingPaymentStatus } from "utilities/helper";

// UI payment status
export const PAID = "paid";
export const APPROVED = "approved";
export const PROCESSING = "processing";
export const SCHEDULED = "scheduled";
export const PENDING = "pending";
export const FAILED = "failed";
export const SCHEDULED_REFUND_STATUS = "scheduled refund";
export const ATTEMPTING_REFUND_STATUS = "pending refund";
export const AUTHORIZED_REFUND_STATUS = "pending refund";
export const REFUNDED_STATUS = "refunded";
export const REFUND_REJECTED_STATUS = "refund failed";
export const REFUND_CANCELLED_STATUS = "refund cancelled";
export const REFUND_REVERSAL_STATUS = "refund cancelled";
export const RETURNED_STATUS = "returned";
export const RETURN_REVERSAL_STATUS = "return cancelled";

export const isSuccessfulPayment = (paymentStatus: string): boolean => {
  if (paymentStatus === null || paymentStatus === undefined) return false;
  const mappedStatus = mappingPaymentStatus(paymentStatus);
  const successfulPaymentStatus = [PAID, SCHEDULED, PENDING, REFUNDED_STATUS];
  return successfulPaymentStatus.includes(mappedStatus);
};
