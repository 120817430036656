// transaction type CHARGE
export const SCHEDULED_AUTHORIZATION = "scheduled_authorization";
export const SCHEDULED_SETTLEMENT = "scheduled_settlement";
export const ATTEMPTING_AUTHORIZATION = "attempting_authorization";
export const RETRYING_AUTHORIZATION = "retrying_authorization";
export const AUTHORIZED = "authorized";
export const AUTHORIZATION_DECLINED = "authorization_declined";
export const SETTLEMENT_REJECTED = "settlement_rejected";
export const CANCELLED = "cancelled";
export const ABORTED = "aborted";
export const BLOCKED = "blocked";
// transaction type REFUND
export const SCHEDULED_REFUND = "scheduled_refund";
export const ATTEMPTING_REFUND = "attempting_refund";
export const AUTHORIZED_REFUND = "authorized_refund";
export const REFUNDED = "refunded";
export const REFUND_REJECTED = "refund_rejected";
export const REFUND_CANCELLED = "refund_cancelled";
export const REFUND_ABORTED = "refund_aborted";
// transaction type REFUND_REVERSAL
export const REFUND_REVERSED = "refund_reversed";
// transaction type RETURN
export const RETURN = "returned";
// transaction type RETURN_REVERSAL
export const RETURN_REVERSED = "return_reversed"