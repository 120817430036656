import { SANDBOX } from "constants/realm";
import { IAccount } from '../types/account';

export const GET_ACCOUNT_REQUEST = "GET_ACCOUNT_REQUEST";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_FAILURE = "GET_ACCOUNT_FAILURE";

export const SET_ACCOUNT_NUMBER = "SET_ACCOUNT_NUMBER";
export const RESET_INITIAL_STATE = "RESET_INITIAL_STATE";


export const setAccountNumber = (accountNumber: string): Record<string, unknown> => ({
    type: SET_ACCOUNT_NUMBER,
    accountNumber: accountNumber,
})

export const resetAccountState = () => ({
    type: RESET_INITIAL_STATE
})

export const accountInitialState: IAccount = {
    accountNumber: "",
    language: "en",
    streetAddress1: '',
    streetAddress2: '',
    locality: '',
    region: '',
    postalCode: '',
    country: '',
    charities: [],
    channel: '',
    fsTypes: [],
    fundingOptions: [],
    paymentIntentId: "",
    balance: {
        currentAmountDue: 0.0,
        dueDate: "",
        lastUpdatedDate: "",
        currentCharges: 0,
        balanceForward: 0.0,
        minimumAmountDue: 0.0,
        billedAmount: 0,
        messageCodes: [],
        fundingRestrictionsInEffect: [],
        lastUpdatedAtSourceDate: "",
        amountDueAfterDueDate: 0
    },
    realm: SANDBOX,
    paypalConfiguration: {
        merchantAccountId: "",
        merchantId: "",
        braintreeToken: "",
        clientId: ""
    },
    googlePayConfiguration: {
        merchantId: "",
        merchantName: "",
        publicKey: ""
    },
    fingerprint: "",
    initiator: {
        userEmail: ""
    },
    restrictionMessages: [],
    processedPayment:null,
    methodOfPayments : ["DD", "DI", "MC", "VI", "KCH"]
};