import React from "react";
import GooglePayButton from "@google-pay/button-react";
import { DigitalPaymentData } from "types/digitalPaymentData";
import IamApi from "api/IamApi";
import FundingOption from "constants/FundingOption";
import { PaymentIntentPrepareRequest } from "types/Api/Payment/paymentIntentPrepareRequest";
import { IGooglePayConfig } from "types/digitalPaymentConfigurations";
import { setAppLoading } from "actions/appStateActions";
import { useDispatch } from "react-redux";
import { LIVE } from "constants/realm";
import { getGoogleCardNetworkByShortCode,getCommonCardNetworks } from "utilities/helper";


export interface IGooglePayButtonWrapperProps {
  country: string;
  currencyCode: string;
  clientId: string;
  paymentIntentId: string;
  amount: number;
  reviewDigitalPayment: (paymentData: DigitalPaymentData) => void;
  verifyPaymentAmount: () => { isValid: boolean };
  onPrepareIntentError: (err: any, prepareRequest: PaymentIntentPrepareRequest) => void;
  config: IGooglePayConfig;
  realm: string;
  methodOfPayments:string[]
}

export const GooglePayButtonWrapper = (props: IGooglePayButtonWrapperProps) => {
  const {
    country,
    currencyCode,
    amount,
    clientId,
    paymentIntentId,
    reviewDigitalPayment,
    verifyPaymentAmount,
    onPrepareIntentError,
    config,
    realm,
    methodOfPayments
  } = props;

  const dispatch = useDispatch();
  

  const supportedCcNetworkForGooglePay: string[] = methodOfPayments.map((method) => {
    return getGoogleCardNetworkByShortCode(method);
  });  

  const supportedCcNetworksForGoogle = getCommonCardNetworks(supportedCcNetworkForGooglePay);

  const environment =
    realm.toLowerCase() === LIVE.toLowerCase() ? "PRODUCTION" : "TEST";

  const paymentDataRequest: google.payments.api.PaymentDataRequest = {
    merchantInfo: {
      merchantName: config.merchantName,
      merchantId: config.merchantId,
    },
    apiVersion: 2,
    apiVersionMinor: 0,
    emailRequired: true,
    shippingAddressRequired: true,
    shippingAddressParameters: {
      allowedCountryCodes: ["US", "CA"],
      phoneNumberRequired: true,
    },
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: supportedCcNetworksForGoogle,
        },
        tokenizationSpecification: {
          type: "DIRECT",
          parameters: {
            protocolVersion: "ECv2",
            publicKey: config.publicKey,
          },
        },
      },
    ],
    transactionInfo: {
      countryCode: country,
      currencyCode: currencyCode,
      totalPriceStatus: "FINAL",
      totalPrice: amount.toFixed(2),
    },
  };


  

  const handleButtonClick = (event: any) => {
    const isValidAmount = verifyPaymentAmount();
    console.log( "verify amount: ", isValidAmount);
    if (!isValidAmount.isValid) {
      event.preventDefault();
      dispatch(setAppLoading(false));
    }
  }
  const handleLoadPaymentData = async (
    payload: google.payments.api.PaymentData
  ) => {
    dispatch(setAppLoading(true));

  

    const token = window.btoa(payload.paymentMethodData.tokenizationData.token);

    const prepareRequest: PaymentIntentPrepareRequest = {
      paymentAmount: amount,
      fundingOption: FundingOption.GooglePay,
      digitalWallet: {
        paymentToken: token,
        cardType: payload.paymentMethodData.info?.cardNetwork,
      },
    };

    await IamApi.ezpay.postPrepareIntent(
      clientId,
      paymentIntentId,
      prepareRequest
    ) .then((response) => {
      const paymentData: DigitalPaymentData = {
        payer: payload.shippingAddress?.name ?? "",
        email: payload.email ?? "",
        mobileNumber: payload.shippingAddress?.phoneNumber,
        paymentToken: token,
        cardType: payload.paymentMethodData.info?.cardNetwork,
        paymentMethod: FundingOption.GooglePay,
        serviceFee: Number(response.data.feeTransactionAmount),
      };
      reviewDigitalPayment(paymentData);
      dispatch(setAppLoading(false));
    })
    .catch((err) => {
     onPrepareIntentError(err, prepareRequest);
     dispatch(setAppLoading(false));
    });
    
  };

  const handleError = (error: Error | google.payments.api.PaymentsError) => {
    console.log(error);
    dispatch(setAppLoading(false));
  };

  return (
    // @ts-ignore
    <GooglePayButton
      style={{
        height: "50px",
        width: "100%",
        alignSelf: "center",
        outline : "none !important"
      }}
      environment={environment}
      buttonSizeMode="fill"
      buttonType="plain"
      paymentRequest={paymentDataRequest}
      onLoadPaymentData={handleLoadPaymentData}
      onError={handleError}
      onClick={handleButtonClick}
    />
  );
};
